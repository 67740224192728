import { SxStyleProp } from "theme-ui"
import { createResponsiveStyles } from "../../../helpers/theme"

export * from "./form"

export const contactUsContainerStyles: SxStyleProp = {
  pb: 7,
  minHeight: "90vh",
  flexDirection: "column",
  pt: createResponsiveStyles(9, 11, 12),
  px: createResponsiveStyles(5, 10, "130px"),
  textAlign: createResponsiveStyles("center", "center", "left"),
  alignItems: createResponsiveStyles("center", "center", "flex-start"),
  justifyContent: createResponsiveStyles("center", "center", "flex-start"),
}

export const contactUsTitleStyles: SxStyleProp = {
  fontWeight: "bold",
  pb: createResponsiveStyles(4, 6),
}

export const contactUsInnerStyles: SxStyleProp = {
  width: "100%",
  flexDirection: createResponsiveStyles("column", "column", "row"),
}

export const contactUsFormContainerStyles: SxStyleProp = {
  width: createResponsiveStyles("100%", "100%", "50%"),
}

export const contactUsSidebarContainerStyles: SxStyleProp = {
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  pl: createResponsiveStyles(0, 0, 9),
  pt: createResponsiveStyles("50px", 8, "0"),
  width: createResponsiveStyles("100%", "100%", "50%"),
}

export const contactUsSidebarBlockStyles: SxStyleProp = {
  py: createResponsiveStyles(5),
  width: "100%",
}

export const contactUsSidebarBlockParagraphStyles: SxStyleProp = {
  fontFamily: "heading",
  pb: createResponsiveStyles(5, 7),
  fontSize: createResponsiveStyles(1, 1, 3),
}

export const contactUsSidebarBlockColoredParagraphStyles: SxStyleProp = {
  color: "primary",
  fontWeight: "bold",
  alignItems: "center",
  justifyContent: "center",
  "> a": {
    color: "primary",
    fontFamily: "heading",
    px: createResponsiveStyles(5),
    fontSize: createResponsiveStyles(1, 1, 3),
    ":hover,:active": {
      textDecoration: "none",
    },
  },
  "> svg > path": {
    fill: "primary",
  },
}
