import { SxStyleProp } from "theme-ui"
import { palette } from "../../../constants/theme/material"
import { createResponsiveStyles } from "../../../helpers/theme"

export const contactFormContainerStyles: SxStyleProp = {
  width: "100%",
  minWidth: "100%",
  minHeight: createResponsiveStyles("575px", "650px"),
  borderRadius: "8px",
  flexDirection: "column",
  mt: createResponsiveStyles(5, 5, 7),
  px: createResponsiveStyles(3, 8, 8),
  py: createResponsiveStyles(5, 7, 7),
  boxShadow: "0 5px 4px 6px rgba(0, 0, 0, 0.03)",
}

export const contactFormTextStyles: SxStyleProp = {
  textAlign: "left",
  fontFamily: "heading",
  fontSize: createResponsiveStyles(1, 1, 3),
}

export const contactFormInnerStyles = (hide: boolean = false): SxStyleProp => ({
  display: hide ? "none" : "block",
  "& .MuiFormControl-root": {
    mt: createResponsiveStyles(3, 6),
  },
  input: {
    width: "100%",
  },
  textarea: {
    width: "100%",
  },
})

export const contactFromSubmitContainerStyles: SxStyleProp = {
  width: "100%",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
}

export const contactFormSubmitContainerErrorStyles: SxStyleProp = {
  pt: 3,
  color: palette.red.main,
  mb: "-15px",
}

export const contactFormSubmitButton: SxStyleProp = {
  m: "0 auto",
  fontWeight: "dark",
  textAlign: "center",
  justifySelf: "center",
  variant: "buttons.primary",
  mt: createResponsiveStyles(6),
  px: createResponsiveStyles(9),
}

export const contactFormSuccessContainerStyles = (
  display: boolean = true
): SxStyleProp => ({
  height: "100%",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  display: display ? "flex" : "none",
  "> p": {
    pt: 6,
    width: "100%",
    color: "primary",
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "heading",
    px: createResponsiveStyles(3, 6, 8),
    fontSize: createResponsiveStyles(1, 1, 3),
  },
  svg: {
    width: "84px",
    path: {
      fill: "primary",
    },
  },
})
