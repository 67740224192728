import React from "react"
import SEO from "../components/seo"
import { useIntl } from "react-intl"
import ContactUs from "../components/contact"
import { translate } from "../helpers/grammar"
import MainLayout from "../components/layouts/main"

/**
 * Component rendering the "/contact" page of the site.
 *
 * @returns {JSX.Element}
 */
const ContactPage = (): JSX.Element => {
  const intl = useIntl()

  // Translate text content for the page
  const pageTitle = translate(intl, "contactUs.title")
  const subTitle = translate(intl, "contactUs.subTitle")
  const howToReach = {
    phone: {
      text: translate(intl, "contactUs.howToReach.phone.text"),
      tel: translate(intl, "contactUs.howToReach.phone.tel"),
    },
    fax: {
      text: translate(intl, "contactUs.howToReach.fax.text"),
      tel: translate(intl, "contactUs.howToReach.fax.tel"),
    },
  }

  // Render the component using "MainLayout"
  return (
    <MainLayout pageTitle={"contact"} routeId={"contact"}>
      <SEO title={pageTitle} />
      <ContactUs
        title={pageTitle}
        subTitle={subTitle}
        howToReach={howToReach}
      />
    </MainLayout>
  )
}

export default ContactPage
