import Axios from "axios"
import { Medication } from "../../interfaces/forms"
import { environmentVariable } from "../../helpers/others"

// Get required API values
const apiBaseUrl = environmentVariable("apiUrl") as string
const apiPolicyNo = environmentVariable("policyNo") as string

// Set base Axios URL
Axios.defaults.baseURL = apiBaseUrl

// Default Headers
const jsonHeaders = {
  "Content-Type": "application/json",
}

/**
 * Type definition for fields that can be sent
 * as part of the Contact Form API request.
 *
 * @interface
 */
interface ContactFormFields {
  name: string
  email: string
  policyNumber: string
  subject: string
  message: string
}

/**
 * Handles sending of the contact form fields to
 * the back-end server.
 *
 * @function
 *
 * @param {ContactFormFields} param
 *
 * @returns {Promise<boolean>}
 */
export const sendContactFormFields = async ({
  email,
  name,
  policyNumber,
  subject,
  message,
}: ContactFormFields): Promise<boolean> => {
  // Fail if API URL is undefined
  if (!apiBaseUrl.length) {
    return Promise.reject(false)
  }

  // Makes a POST request
  return await Axios.post(
    "/contact_form",
    {
      Email: email,
      Name: name,
      Policy: policyNumber,
      Subject: subject,
      Message: message,
    },
    {
      headers: { ...jsonHeaders },
      responseType: "json",
    }
  )
    .then(success => Promise.resolve(true))
    .catch(error => Promise.reject(false))
}

/**
 * Handles getting the medications & disease
 * forms from the back-end server.
 *
 * @function
 *
 * @param {string} language
 *
 * @returns {Promise<Medication[]>}
 */
export const getMedications = async (
  language: string = "EN"
): Promise<Medication[]> => {
  // Return an empty array if API URL or Policy No. are undefined
  if (!apiBaseUrl.length || !apiPolicyNo.length) {
    return Promise.resolve([])
  }

  // Makes a GET request
  return await Axios.get(`/facet_forms?policy=${apiPolicyNo}`, {
    headers: { ...jsonHeaders, "Accept-Language": language.toUpperCase() },
    responseType: "json",
  })
    .then(response => Promise.resolve(response.data.data))
    .catch(error => Promise.resolve([]))
}

/**
 * Type definition for fields that can be sent
 * as part of the Send Form API request.
 *
 * @interface
 */
interface SendFormFields {
  emails: string[]
  form: string
  isUBCMember: boolean
  language: string
}

/**
 * Handles sending of the disease form request to
 * the back-end server.
 *
 * @function
 *
 * @param {ContactFormFields} param
 *
 * @returns {Promise<boolean>}
 */
export const sendFormFields = async ({
  emails,
  form,
  isUBCMember,
  language = "EN",
}: SendFormFields): Promise<boolean> => {
  // Fail if API URL or Policy No. are undefined
  if (!apiBaseUrl.length || !apiPolicyNo.length) {
    return Promise.reject(false)
  }

  // Makes a POST request
  return await Axios.post(
    `/send_form`,
    {
      Email: emails.join(";"),
      Policy: apiPolicyNo,
      Form: form,
      IsUBCMember: isUBCMember,
    },
    {
      headers: { ...jsonHeaders, "Accept-Language": language.toUpperCase() },
      responseType: "json",
    }
  )
    .then(response => Promise.resolve(true))
    .catch(error => Promise.reject(false))
}
