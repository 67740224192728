/** @jsx jsx */
import {
  contactUsInnerStyles,
  contactUsTitleStyles,
  contactUsContainerStyles,
  contactUsSidebarBlockStyles,
  contactUsFormContainerStyles,
  contactUsSidebarContainerStyles,
  contactUsSidebarBlockParagraphStyles,
  contactUsSidebarBlockColoredParagraphStyles,
} from "./styles"
import ContactForm from "./form"
import { Styled, jsx, Flex, Box } from "theme-ui"
import FaxIcon from "./../../images/common/icons/contact/fax.inline.svg"
import PhoneIcon from "./../../images/common/icons/contact/phone.inline.svg"

/**
 * Type definition for props required for "ContactUs" component.
 *
 * @interface
 */
interface ContactUsProps {
  title: string
  subTitle: string
  howToReach: {
    [key: string]: {
      text: string
      tel: string
    }
  }
}

const ContactUs = ({ title, subTitle, howToReach }: ContactUsProps) => {
  return (
    <Flex sx={contactUsContainerStyles}>
      <Styled.h2 sx={contactUsTitleStyles}>{title}</Styled.h2>
      <Styled.p>{subTitle}</Styled.p>
      <Flex sx={contactUsInnerStyles}>
        <Flex sx={contactUsFormContainerStyles}>
          <ContactForm />
        </Flex>
        <Flex sx={contactUsSidebarContainerStyles}>
          <Box sx={contactUsSidebarBlockStyles}>
            <Styled.p sx={contactUsSidebarBlockParagraphStyles}>
              {howToReach.phone.text}
            </Styled.p>
            <Flex sx={contactUsSidebarBlockColoredParagraphStyles}>
              <PhoneIcon />
              <a href={`tel:${howToReach.phone.tel}`}>{howToReach.phone.tel}</a>
            </Flex>
          </Box>
          <Box sx={contactUsSidebarBlockStyles}>
            <Styled.p sx={contactUsSidebarBlockParagraphStyles}>
              {howToReach.fax.text}
            </Styled.p>
            <Flex sx={contactUsSidebarBlockColoredParagraphStyles}>
              <FaxIcon />
              <a href={`tel:${howToReach.fax.tel}`}>{howToReach.fax.tel}</a>
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ContactUs
