/** @jsx jsx */
import {
  contactFormTextStyles,
  contactFormInnerStyles,
  contactFormSubmitButton,
  contactFormContainerStyles,
  contactFromSubmitContainerStyles,
  contactFormSuccessContainerStyles,
  contactFormSubmitContainerErrorStyles,
} from "./../styles"
import {
  trim,
  validateLength,
  validateEmail as validateEmailString,
} from "../../../helpers/string"
import { useIntl } from "react-intl"
import { CustomTextField } from "../../common"
import { jsx, Flex, Styled, Box } from "theme-ui"
import { translate } from "../../../helpers/grammar"
import { sendContactFormFields } from "../../../utils/api"
import { useState, ChangeEvent, FormEventHandler, FormEvent } from "react"
import SuccessIcon from "../../../images/common/icons/contact/form-success.inline.svg"

/**
 * Component rendering the contact form on "Contact" page.
 *
 * Use only on "contact" page.
 *
 * @returns {JSX.Element}
 */
const ContactForm = (): JSX.Element => {
  const intl = useIntl()
  const [email, setEmail] = useState<string>("")
  const [emailError, setEmailError] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [nameError, setNameError] = useState<string>("")
  const [policyNumber, setPolicyNumber] = useState<string>("")
  const [policyNumberError, setPolicyNumberError] = useState<string>("")
  const [subject, setSubject] = useState<string>("")
  const [subjectError, setSubjectError] = useState<string>("")
  const [message, setMessage] = useState<string>("")
  const [messageError, setMessageError] = useState<string>("")
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false)
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [formError, setFormError] = useState<boolean>(false)

  /**
   * Handle "email" change
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const emailChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value

    setEmail(currentValue)

    validateEmail(currentValue)
  }
  const validateEmail = (input?: string): void => {
    input = input || email
    const trimmedInput = trim(input)

    if (!trimmedInput.length) {
      setEmailError(translate(intl, "contactUs.form.errors.email.required"))
    } else if (trimmedInput.length && !validateEmailString(trimmedInput)) {
      setEmailError(translate(intl, "contactUs.form.errors.email.invalid"))
    } else {
      setEmailError("")
    }
  }

  /**
   * Handles "name" change
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const nameChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value

    setName(currentValue)

    validateName(currentValue)
  }
  const validateName = (input?: string): void => {
    input = input || name
    const trimmedInput = trim(input)

    if (!trimmedInput.length) {
      setNameError(translate(intl, "contactUs.form.errors.name.required"))
    } else if (trimmedInput.length && !validateLength(trimmedInput)) {
      setNameError(translate(intl, "contactUs.form.errors.name.invalid"))
    } else {
      setNameError("")
    }
  }

  /**
   * Handle "subject" change
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const subjectChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value

    setSubject(currentValue)

    validateSubject(currentValue)
  }
  const validateSubject = (input?: string): void => {
    input = input || subject
    const trimmedInput = trim(input)

    if (!trimmedInput.length) {
      setSubjectError(translate(intl, "contactUs.form.errors.subject.required"))
    } else if (trimmedInput.length && !validateLength(trimmedInput)) {
      setSubjectError(translate(intl, "contactUs.form.errors.subject.invalid"))
    } else {
      setSubjectError("")
    }
  }

  /**
   * Handle "message" change
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const messageChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value

    setMessage(currentValue)

    validateMessage(currentValue)
  }
  const validateMessage = (input?: string): void => {
    input = input || message
    const trimmedInput = trim(input)

    if (!trimmedInput.length) {
      setMessageError(translate(intl, "contactUs.form.errors.message.required"))
    } else if (trimmedInput.length && !validateLength(trimmedInput, 2, 1000)) {
      setMessageError(translate(intl, "contactUs.form.errors.message.invalid"))
    } else {
      setMessageError("")
    }
  }

  /**
   * Handles "policyNumber" change
   *
   * @param {ChangeEvent<HTMLInputElement>} e
   *
   * @returns {void}
   */
  const policyNumberChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const currentValue = e.currentTarget.value

    setPolicyNumber(currentValue)

    validatePolicyNumber(currentValue)
  }
  const validatePolicyNumber = (input?: string): void => {
    input = input || policyNumber
    const trimmedInput = trim(input)

    if (trimmedInput.length && !validateLength(input)) {
      setPolicyNumberError(
        translate(intl, "contactUs.form.errors.policyNumber.invalid")
      )
    } else {
      setPolicyNumberError("")
    }
  }

  /**
   * Handle "onSubmit" event for form element.
   *
   * @param {FormEvent<Element>} e
   *
   * @returns {Promise<void>}
   */
  const formSubmit: FormEventHandler = async (
    e: FormEvent<Element>
  ): Promise<void> => {
    e.preventDefault()

    validateName()
    validateEmail()
    validateMessage()
    validateSubject()
    validatePolicyNumber()

    const trimmedName = trim(name)
    const trimmedEmail = trim(email)
    const trimmedSubject = trim(subject)
    const trimmedMessage = trim(message)
    const trimmedPolicyNumber = trim(policyNumber)

    // Validate for no errors
    if (
      !trimmedName.length ||
      !trimmedEmail.length ||
      !trimmedSubject.length ||
      !trimmedMessage.length ||
      nameError.length ||
      emailError.length ||
      subjectError.length ||
      messageError.length ||
      policyNumberError.length
    ) {
      return
    }

    // Make button disabled
    setFormSubmitting(true)

    return await sendContactFormFields({
      email: trimmedEmail,
      name: trimmedName,
      policyNumber: trimmedPolicyNumber,
      subject: trimmedSubject,
      message: trimmedMessage,
    })
      .then(success => {
        setFormError(false)
        setFormSubmitting(false)
        setFormSubmitted(true)
        return
      })
      .catch(error => {
        setFormError(true)
        setFormSubmitting(false)
        return
      })
  }

  return (
    <Flex sx={contactFormContainerStyles}>
      <Box sx={contactFormInnerStyles(formSubmitted)}>
        <Styled.p sx={contactFormTextStyles}>
          {translate(intl, "contactUs.form.text")}
        </Styled.p>
        <form onSubmit={formSubmit} noValidate={true} autoComplete={"off"}>
          <CustomTextField
            error={emailError.length ? true : false}
            required={true}
            helperText={emailError}
            fullWidth={true}
            value={email}
            label={translate(intl, "contactUs.form.labels.email")}
            onChange={emailChange}
            onBlur={e => setEmail(e.currentTarget.value.trim())}
          />
          <CustomTextField
            error={nameError.length ? true : false}
            required={true}
            helperText={nameError}
            fullWidth={true}
            label={translate(intl, "contactUs.form.labels.name")}
            value={name}
            onChange={nameChange}
            onBlur={e => setName(e.currentTarget.value.trim())}
          />
          <CustomTextField
            error={policyNumberError.length ? true : false}
            fullWidth={true}
            helperText={policyNumberError}
            label={translate(intl, "contactUs.form.labels.policyNumber")}
            value={policyNumber}
            onChange={policyNumberChange}
            onBlur={e => setPolicyNumber(e.currentTarget.value.trim())}
          />
          <CustomTextField
            error={subjectError.length ? true : false}
            required={true}
            helperText={subjectError}
            fullWidth={true}
            label={translate(intl, "contactUs.form.labels.subject")}
            value={subject}
            onChange={subjectChange}
            onBlur={e => setSubject(e.currentTarget.value.trim())}
          />
          <CustomTextField
            error={messageError.length ? true : false}
            required={true}
            helperText={messageError}
            rows={5}
            fullWidth={true}
            multiline={true}
            label={translate(intl, "contactUs.form.labels.message")}
            value={message}
            onChange={messageChange}
            onBlur={e => setMessage(e.currentTarget.value.trim())}
          />
          <Flex sx={contactFromSubmitContainerStyles}>
            {formError ? (
              <Styled.p sx={contactFormSubmitContainerErrorStyles}>
                {translate(intl, "contactUs.form.errors.main")}
              </Styled.p>
            ) : null}
            <button
              type={"submit"}
              disabled={formSubmitting}
              sx={contactFormSubmitButton}
              className={formSubmitting ? "Disabled" : ""}
            >
              {!formSubmitting
                ? translate(intl, "contactUs.form.button.submit")
                : `${translate(intl, "contactUs.form.button.submitting")}...`}
            </button>
          </Flex>
        </form>
      </Box>
      <Flex sx={contactFormSuccessContainerStyles(formSubmitted)}>
        <SuccessIcon />
        <Styled.p>{translate(intl, "contactUs.form.successMessage")}</Styled.p>
      </Flex>
    </Flex>
  )
}

export default ContactForm
